<!-- Content.vue -->
 
<template>
  <div id="content" style="width:100%; height:90%; background-color:wheat;">
    Not Found Page
  </div>

</template>
 
<script>

export default {
  beforeMount() {
    this.$router.push('main');
  }
};
</script>